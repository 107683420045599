import * as React from 'react';
import { Grid, Typography } from '@mui/material';
import theme from '../../../core-utils/theme';
import IconFromSvg from '../IconFromSvg';

interface ILabelWithIconProps {
  label: string;
  path: string;
  alt?: string;
  colored?: boolean;
  label2?: string;
}

const LabelWithIcon = ({
  label,
  path,
  alt,
  colored,
  label2,
}: ILabelWithIconProps) => {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      sx={{ cursor: 'pointer' }}
    >
      {colored ? (
        <Typography
          sx={{ color: theme.palette.text.primary, ...theme.typography.body1 }}
        >
          {label}
        </Typography>
      ) : (
        <Typography
          sx={{
            color: theme.palette.text.secondary,
            ...theme.typography.body2,
          }}
        >
          {label}
        </Typography>
      )}
      {label2 && (
        <Typography
          color={colored ? 'text.primary' : 'text.disabled'}
          variant={colored ? 'body1' : 'body2'}
        >
          {label2}
        </Typography>
      )}

      <IconFromSvg path={path} alt={alt ? alt : ''} />
    </Grid>
  );
};

export default LabelWithIcon;
