import { Box } from '@mui/material';
import MainFooter from '../../molecules/MainFooter';
import React from 'react';
import theme from '../../../core-utils/theme';

interface IHomeTemplate {
  leftNavComponent?: any;
  header?: any;
  content?: any;
}

const HomeTemplate: React.FC<IHomeTemplate> = ({
  leftNavComponent,
  header,
  content,
}) => {
  return (
    <Box height="100%">
      {header}
      <Box
        display="flex"
        overflow="overlay"
        position="absolute"
        width="100%"
        height={`calc(100% - ${theme.spacing(16.25)})`}
      >
        <Box>{leftNavComponent}</Box>
        <Box id="box" display="flex" flexDirection="column" width="100%">
          <Box
            sx={{
              width: '100%',
              flex: '1 0 auto',
            }}
          >
            {content}
          </Box>
          <Box flexShrink={0} width="100%">
            <MainFooter
              versionRequired={true}
              logoRequired={false}
            ></MainFooter>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeTemplate;
