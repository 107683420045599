import CssBaseline from '@mui/material/CssBaseline';
import React, { useEffect } from 'react';
import { RenderCtrlProvider } from 'react-render-ctrl';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { PrivateRoute } from './_components/organisms/PrivateRoute';
import AppDetailsPage from './_components/pages/AppDetailsPage';
import HelpDetailsPage from './_components/pages/HelpDetailsPage';
import Home from './_components/pages/Home';
import OnboardingPage from './_components/pages/OnboardingPage';
import PolicyBuilderPage from './_components/pages/PolicyBuilder';
import SessionRecordingDetailPage from './_components/pages/SessionRecordingDetailPage';
import UserDetailsPage from './_components/pages/UserDetailsPage';
import UserGroupDetailPage from './_components/pages/UserGroupDetailPage';
import UserLogin from './_components/pages/UserLogin';
import UserLogoutPage from './_components/pages/UserLogout';
import UserMfaLoginPage from './_components/pages/UserMfaLoginPage';
import UserOnboardingPage from './_components/pages/UserOnboardingPage';
import { ProvideAuth } from './core-utils/Auth/index.hook';
import { getRole } from './core-utils/Helper/helper';
import { ROUTES } from './core-utils/routes';
import './global.css';
import { ErrorBoundary, RemoteMonitor, ThemeWrapper } from './wrappers';
import SettingsService from '@service/settings-service/SettingsService';
import store, { useAppDispatch } from '@redux/Store/store';
import { updateHeaderData } from './Redux/Reducers/header';

const App = () => {

  const dispatch = useAppDispatch();

  useEffect(() => {
   SettingsService.getOrgSettings()
     .then((settings) => {
      dispatch(updateHeaderData(settings),);
   })
   .catch((e) => {
      console.error("Unable to fetch organization details", e);
   });
  }, [])

  return (
    <RemoteMonitor>
      <React.StrictMode>
        <ThemeWrapper>
          <CssBaseline>
            <RenderCtrlProvider
              ErrorComponent={() => <div>default error hint</div>}
              EmptyComponent={() => <div>default empty hint</div>}
              LoadingComponent={() => <div>default loading hint</div>}
            >
              <ErrorBoundary>
                <ProvideAuth>
                  <BrowserRouter
                    basename={
                      '/' + process.env.BASE_URL ? process.env.BASE_URL : ''
                    }
                  >
                    <React.Suspense fallback={<div />}>
                      <Switch>
                        <PrivateRoute
                          path={ROUTES.USER_DETAILS}
                          exact
                          component={(props: any) => (
                            <UserDetailsPage {...props} />
                          )}
                        />

                        <PrivateRoute
                          path={ROUTES.USER_GROUP_DETAILS}
                          exact
                          component={(props: any) => (
                            <UserGroupDetailPage {...props} />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path={ROUTES.APP_DETAILS}
                          component={(props: any) => (
                            <AppDetailsPage {...props} />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path={ROUTES.HELP_DETAILS}
                          component={(props: any) => (
                            <HelpDetailsPage {...props} />
                          )}
                        />
                        <PrivateRoute
                          exact
                          path={ROUTES.SESSION_RECORDING}
                          component={(props: any) => (
                            <SessionRecordingDetailPage {...props} />
                          )}
                        />
                        {/* <Route
                          path={ROUTES.SESSION_TOKEN}
                          exact
                          component={() => <Auth setToken={setToken} />}
                        />
                        <Route
                          exact
                          path={ROUTES.SESSION_TIMEOUT}
                          component={() => <SessionTimeout />}
                        /> */}
                        <Route
                          exact
                          path={ROUTES.LOGIN}
                          component={UserLogin}
                        />
                        <Route
                            exact
                            path={ROUTES.MFA_LOGIN}
                            component={UserMfaLoginPage}
                        />
                        <Route
                          exact
                          path={ROUTES.LOGOUT}
                          component={UserLogoutPage}
                        />
                        <PrivateRoute
                          exact
                          path={ROUTES.CREATE_POLICY}
                          component={PolicyBuilderPage}
                        />
                        <PrivateRoute
                          exact
                          path={ROUTES.EDIT_POLICY}
                          component={PolicyBuilderPage}
                        />
                        <Route
                          exact
                          path={ROUTES.ONBOARDING}
                          component={OnboardingPage}
                        />
                        <Route
                          exact
                          path={ROUTES.USER_ONBOARDING}
                          component={UserOnboardingPage}
                        />
                        <Redirect
                          exact
                          from={ROUTES.HOME}
                          to={
                            getRole() === 'admin'
                              ? ROUTES.DASHBOARD
                              : ROUTES.MY_APPLICATIONS
                          }
                        />
                        <Redirect 
                          exact
                          from={ROUTES.ADMIN}
                          to={`${ROUTES.LOGIN}?sr=1`}
                        />
                        {/* Intetionally adding this at the end, Add all routes above this */}
                        <PrivateRoute path={ROUTES.HOME} component={Home} />
                      </Switch>
                    </React.Suspense>
                  </BrowserRouter>
                </ProvideAuth>
              </ErrorBoundary>
            </RenderCtrlProvider>
          </CssBaseline>
        </ThemeWrapper>
      </React.StrictMode>
    </RemoteMonitor>
  );
};

export default App;
