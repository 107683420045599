const settingsMessages = {
  TAB_NAMES: {
    PROFILE: 'Profile',
    SESSION: 'Session',
    ACCOUNT: 'Account',
    LICENSING: 'Licensing',
    IDENTITY_MANAGEMENT: 'Identity Management',
    AUTHENTICATION: 'Identity Provider',
    USER_SESSIONS: 'User Sessions',
    LOGS: 'Logs',
    NOTIFICATION: 'Notifications',
    NETWORK: 'Network',
    APP_HOSTS: 'Desktop App Hosts',
    EXTENSIONS: 'Extensions'
  },
  SSO_SETTINGS_TAB: {
    API_KEY: 'API Key',
    API_KEY_SUBHEADING:
      'Enter the API key below to authorize your account with Okta',
    PLACEHOLDER: 'Enter API Key here',
    SECRET_KEY_PLACEHOLDER: 'Enter Secret Key here',
    PRIVATE_KEY_PLACEHOLDER: 'Enter Private Key here',
    IDP_URL_PLACEHOLDER: 'Enter URL here',
    IDP_PROVIDER: 'IDP Provider',
    IMPORT_USERS: 'Import Users from {identityProvider}',
    SYNC: 'Sync',
    SYNCING: 'Syncing...',
    SYNCED: 'Synced',
    SYNC_FAILED: 'Encountered an error while syncing. Please try again later.',
    SYNC_YOUR_ACCOUNT:
      'Sync your account with {identityProvider} to import users in bulk',
    USER_ATTRIBUTES: 'User Attributes',
    SELECT_ATTRIBUTES: 'Select the attributes that need to be synced from Okta',
    ATTRIBUTES_LABEL: 'Attributes',
    ATTRIBUTES_PLACEHOLDER: 'Select the attributes below',
    IDP_TYPE: 'IDP Type',
    SELECT_IDP_TYPE: 'Select the identity Provider you want to configure',
    SAVE_BUTTON: 'Save',
    UNLINK: 'Unlink',
    UNLINK_IDP_QUESTION: 'Unlink {identityProvider} Auth?',
    UNLINK_IDP_MAIN_TEXT:
      'This will remove all users and user groups imported from {identityProvider}. It will not affect the configured applications or policies. This action is irreversible.',
    GROUP_FILTERS_HELP_TEXT:
      'Enter comma separated list of group names to sync, leave it blank to sync all groups.',
    FILL_DETAILS_FROM_IDP_CONFIGURATION:
      'Fill in details from {identityProvider} authentication configuration',
    PING_SETTING_LABEL: 'Ping Settings',
    AZURE_ACTIVE_DIRECTORY_SETTINGS_LABEL: 'Azure Active Directory Settings',
    APPLICATION_FILTERS_HELP_TEXT:
      'Enter comma separated list of application names to sync, leave it blank to sync all applications.',
    NO_OF_FILTERS: '${noOfFilters} filter',
    TEXT_FIELD_LABELS: {
      IDP: {
        TYPE_LABEL: 'IDP Type',
        OKTA_AUTH_TYPE: 'Auth Type',
        URL_LABEL: 'IDP URL',
        NAME_LABEL: 'IDP Name',
        SERVICE_ACCOUNT_EMAIL: 'Service Account Email',
        DOMAIN: 'Domain',
        GROUPS_TO_SYNC: 'Groups to sync',
        APPLICATION_TO_SYNC: 'Applications to sync',
        API_KEY: 'API Key',
        GOOGLE: {
          JSON_FILE: 'Service Account Credentials JSON File',
          INVALID_FILE_TYPE: 'Invalid file type. Please upload a JSON file',
          MAX_FILE_SIZE_UPLOAD: 'Max File Size: 50KB',
          UPLOAD_FILE_LENGTH_EXCEED: 'File size must not exceed 50KB',
          JSON_PARSE_ERROR: 'Error parsing JSON file',
          PRIVATE_KEY: 'Private Key',
        },
        AZURE_AD: {
          TENANT_ID: 'Tenant ID',
          CLIENT_ID: 'Client ID',
          CLIENT_SECRET: 'Private Key',
        },
        PING: {
          ENV_ID: 'Env ID',
          CLIENT_ID: 'Client ID',
          CLIENT_SECRET: 'Client Key',
        },
        OKTA: {
          DASHBOARD_PROTECTION: 'Enable Sonet Protected Dashboard',
          HEADING: 'Okta Settings',
          AUTH_TYPE_API_KEY: 'API Key',
          AUTH_TYPE_OAuth: 'OAuth 2.0',

        }
      },
    },
    ERROR_LABELS: {
      CLIENT_ID: 'Client ID is required.',
      ENV_ID: 'Env ID is required.',
      SECERET_KEY: 'Secret Key is required.',
      TENANT_ID: 'Tenant ID is required.',
      API_KEY: 'API key is required.',
      FILE_UPLOAD_ERROR: 'Service Account Credentials file upload is mandatory.',
      DOMAIN: 'Domain is required.',
      IDP_URL: 'IDP url is required.',
      GENERIC_MSG: 'Something went wrong',
    }
  },
  USER_ROLE: 'Role',
  USER_ROLE_DESCRIPTION: 'Select Role',
  ACCOUNT_ORGANIZATION_LOGO: 'Organization Logo',
  SETTINGS_HEADING: 'Settings',
  SUB_TEXT: 'Update and manage your account',
  SETTINGS_TEXT_FIELD_LABELS: {
    ORGANIZATION: 'Organization Name',
    ADDRESS: 'Organization Address',
    EMAIL: 'Email Address',
    LANGUAGE: 'Language',
    TIME_ZONE: 'Time Zone',
  },
  SETTING_BUTTON_LABELS: {
    CHANGE_IMAGE: 'Change Image',
    REMOVE: 'Remove',
  },
  ERROR_MESSAGES: {
    INVALID_EMAIL: 'Invalid Email Id',
    NAME_REQUIRED: 'Name is required',
    ERROR_IP: 'Please Enter a Valid IP Address',
    ERROR_PORT: 'Port Can Range between 0 and 65535',
    ERROR_DISKSPACE: 'Disk Rotation should be greater than 0',
    ERROR_TIME_ROTATION: 'Time should be greater than 0',
    ERROR_RETENTION_PERIOD: 'Value should be greater than 0',
    ERROR_AUTHENTICATION_TIMEOUT: 'Value should be between {minValue} to {maxValue} minutes',
    ERROR_YEARS_MONTHS: 'Value should be atleast 0 or more',
    ERROR_LINES_PER_PAGE: 'Lines Per Page should be greater than 0',
    ERROR_CHECKBOXES: 'Please Select atleast One Checkbox',
    UNSAFE_LOGO_IMAGE: 'Image provided is not a supported image format: {mimeType} is not a allowed.',
    TOO_BIG_LOGO_IMAGE: 'Logo image selected is too big ({fileSize}). Please select an image less than 5MB.'
  },
  SIEM: {
    API_KEY_LABEL: 'SIEM Integration API Key',
    API_KEY_DESCRIPTION:
      'Generate an API key to use for SIEM of your choice. Every new API key overrides the previous one.',
    GENERATE_API_KEY: 'Get an API Key',
    GENERATE_NEW_API_KEY: 'Generate a new API Key',
    COPIED_TO_CLIPBOARD: 'API key is copied to clipboard.',
    COPY_ERROR: 'Unable to copy API key to keyboard. Please copy manually!',
    CONFIRM_MODAL: {
      TITLE: 'Delete SIEM API Key?',
      CONTENT:
        'Are you sure you want to delete SIEM API key? All your existing integrations with this key will stop working. You can generate a new API key for your future integrations.',
    },
    DELETE: {
      SUCCESS: 'API key is deleted.',
      FAILED: 'Failed to delete API key : {reason}',
    },
    CREATE: {
      FAILED: 'Unable to generate a new API Key: {reason}',
      SUCCESS:
        'Keep a copy of the API key as you will be able to copy only now.',
    },
  },
  LINES_PER_PAGE_TEXT: {
    LINES_PER_PAGE: 'Lines Per Page',
    LINES_PER_LOG_PAGE: 'Log lines to display',
    SUBHEADING:
      'Specify the number of log lines per page.The default value is 100.',
  },
  ROTATION_PERIOD_TEXT: {
    ROTATION_PERIOD: 'Rotation Period',
    SUBHEADING2: 'Specify the rotation period .',
    DISK_LABEL: 'Disk (Default 16MB)',
    TIME_LABEL: 'Time',
    DAYS: 'Day(s)',
    MB: 'MB',
  },
  RETENTION_PERIOD_TEXT: {
    RETENTION_PERIOD: 'Retention Period',
    YEARS: 'Years',
    MONTHS: 'Months',
    DAYS: 'Days',
    SELECT_RETENTION_DURATION: 'Select the duration for archiving logs.',
  },
  REMOTE_SYSLOG_SERVERS_TEXT: {
    REMOTE_SYSLOG_SERVERS: 'Remote Syslog Servers',
    REMOTE_SYSLOG_SUBHEADING:
      'Displays the controls for configuring new log servers',
    REMOTE_SYSLOG_BUTTON_TEXT: 'Add New Log Server',
  },
  DEFAULT_EXPORT_FORMAT_TEXT: {
    DEFAULT_EXPORT_FORMAT: 'Default Export Format',
    DEFAULT_EXPORT_FORMAT_SUBTITLE:
      'Specify one or more formats to download logs.These options will be visible while exporting logs.',
    CSV: 'Comma-Seperated Values(.csv)',
    TXT: 'Text File(.txt)',
    JSON: 'JavaScript Object Notation(.json)',
  },
  TABLE_HEADERS: {
    IP_ADDRESS: 'IP Address',
    PORT: 'Port',
    LOG_SEVERITY: 'Log Severity',
    ACTIONS: 'Actions',
  },
  FORM_TEXT: {
    REQUIRED: 'Required',
    CANCEL: 'Cancel',
    SAVE: 'Save',
    ENABLE: 'ENABLE',
    SERVER_IP: 'Server IP',
    PORT: 'Port',
    FREQUENCY_RANGE: 'Frequency Range',
    SERVER_IP_FIELD_NAME: 'serverIP',
    PORT_FIELD_NAME: 'port',
  },
  ICON_TEXT: {
    DELETE: 'Delete',
    EDIT: 'Edit',
  },
  CHANGE_ROLE: {
    ACTION: {
      PROMOTE: 'Promote',
      DEMOTE: 'Demote'
    },
    TITLE: {
      PROMOTE: 'Promote User to Admin',
      DEMOTE: 'Demote User from Admin',
    },
    CONFIRM_MSG: 'Are you sure you want to {action} {userName}?',
    SUCCESS_MSG: {
      PROMOTE: 'User {userName} is promoted successfully.',
      DEMOTE: 'User {userName} is demoted successfully.',
    },
    ERROR_MSG: {
      PROMOTE: 'Failed to promote user {userName}. - {errorMsg}.',
      DEMOTE: 'Failed to demote user {userName}. - {errorMsg}.',
    }
  },
  SAVE_CHANGES: 'Save changes',
  LEAVE_CONFIRMATION_MESSAGE:
    "You haven't saved your changes. Are you sure you want to leave this form?",
  LICENCING_TAB_TEXT: {
    ADDONS: {
      STORAGE: 'storage',
      BANDWIDTH: 'bandwidth',
      BEHAVIOURAL_ANALYTICS: 'behavioural_analytics',
      CONTENT_INSPECTION: 'content_inspection',
      DLP: 'dlp',
      AIML: 'ai-ml',
      USER: 'user',
    },
    ADDON_UNITS: {
      STORAGE: 'GB',
      BANDWIDTH: 'GBPS',
    },
    PRICING_PLAN_HEADING: 'Upgrade Your Plan',
    PRICING_PLAN_SUBTEXT: 'Choose a plan that best suits your needs',
    NO_ADDON: 'No addOns Added',
    EXTRA_ADDON: 'Explore Add-Ons',
  },
  CHECKOUT_MODEL_TEXT: {
    HEADER: 'Customise Subscription With Add-ons',
    SUB_HEADER:
      'Choose the quantity of add-ons required for you current subscription below.',
  },
  NETWORK_SETTINGS: {
    ADD: 'Add',
    SAVE: 'Save',
    DOMAIN: 'Domain',
    NAMESERVER_IPS: 'Name Server IPs',
    ACTIONS: 'Actions',
    CREATE_DNS_CONFIGURATION: 'Create Private DNS Configuration',
    PRIVATE_DNS_CONFIGURATION: 'Private DNS Configuration',
    TITLE: 'Admin configures DNS nameservers used to resolve private domains',
    VALIDATION_ERROR: {
      DOMAIN: 'Please input a valid domain.',
      NAMESERVERS: 'Please provide a semicolon (;) separated list of IP addresses.',
    },
    NO_DNS_CONFIGURATIONS_MSG: 'No private DNS configurations found',
    ERROR: {
      POST: 'Unable to save Private DNS Configuration {errorMsg}',
      GET: 'Unable to fetch Private DNS Configurations.',
      DELETE: 'Unable to delete Private DNS Configurations {errorMsg}'
    },
    SUCCESS: {
      POST: 'Private DNS Configuration is saved.',
      DELETE: 'Private DNS Configuration is deleted.',
    }
  },
  DESKTOP_APP_HOST: {
    TITLE: 'App Host VMs',
    NO_DATA: 'There are no App Host VMs',
    CONNECT_TO_VM: 'connect to the VM"',
    SNAPSHOT_VM: 'take a snapshot'
  },
  EXTENSIONS: {
    TITLE: 'Browser Extensions',
    SUB_HEADING: 'You can upload crx or zip files of your chrome extensions to make them available to all your users.',
    NO_DATA: 'There are no browser extensions configured.',
    NO_DATA_HELPER_TEXT: 'Add a browser extension to get started. You can upload crx or zip files of your chrome extensions to make them available to all your users.',
    ADD_AN_EXTENSION: 'Add an extension',
    ADD_A_NEW_EXTENSION: 'Add a new extension',
    DELETE_EXTENSION: 'Delete Extension',
    NAME: 'Name',
    DESCRIPTION: 'Description',
    ID: 'Extension ID',
    LOGO: 'Logo',
    UPLOAD: {
      TITLE: 'Extension File',
      EXTENSION_FILE: 'Extension file in .crx or .zip format',
      INVALID_FILE_TYPE: 'Invalid file type. Please upload a .crx or .zip file',
      MAX_FILE_SIZE_UPLOAD: 'Max File Size: 4MB',
      UPLOAD_FILE_LENGTH_EXCEED: 'File size must not exceed 4MB',
      CRX_PARSE_ERROR: 'Error parsing extension file',
      SAVE: 'Push Extension',
      LOGO: 'Logo',
      ALLOWED_FILE_TYPES_TOOLTIP: 'Only images of type png, jpg, svg, bmp, webp and gif are allowed.'
    },
    DELETE: {
      CONFIRMATION: 'Are you sure you want to delete "{name}" extension?',
      HEADER: 'Delete Extension?',
      ACTION: 'Delete',
    }
  }
};
export default settingsMessages;
