import React from 'react';
import {
  Grid, TableCell,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';

import { LOGS_TABLE_ITEMS_WIDTH } from '@utils/constants';
import { formatTimeHoursMinuteSecond, getDateAndTimeFromISO } from '@utils/Helper/helper';
import { EXTRA_COLORS } from '@utils/theme';

export enum COLUMN_NAME {
  FULL_NAME = 'FullName',
  USERNAME = 'Username',
  USER_GROUP = 'Usergroup',
  SESSION_START_TIME = 'Started at',
  SESSION_END_TIME = 'Ended at',
  SESSION_DURATION = 'Duration',
  APPS = 'Application',
  UTC = 'UTC'
}

export interface IReportsTableRow {
  fullName: string;
  userGroups: string[] | string | null;
  userName: string;
  applicationList: string;
  loginSessionId: string;
  appSessionId: string;
  loginStartDate: string;
  loginEndDate: string;
  appStartDate: string;
  appEndDate: string;
  utcValue: string
}

export interface IReportsTableRowDisplay {
  [COLUMN_NAME.FULL_NAME]: {
    value: string;
    display: boolean;
  };
  [COLUMN_NAME.USERNAME]: {
    value: string;
    display: boolean;
  };
  [COLUMN_NAME.USER_GROUP]: {
    value: string;
    display: boolean;
  };
  [COLUMN_NAME.SESSION_START_TIME]: {
    value: string;
    display: boolean;
  };
  [COLUMN_NAME.SESSION_END_TIME]: {
    value: string;
    display: boolean;
  };
  [COLUMN_NAME.SESSION_DURATION]: {
    value: string;
    display: boolean;
  };
  [COLUMN_NAME.APPS]: {
    value: string;
    display: boolean;
  };
  [COLUMN_NAME.UTC]: {
    value: string;
    display: boolean;
  };
}

const ReportTableRow = (props: IReportsTableRowDisplay) => {
  const theme = useTheme();
  
  const renderTimeStamp = (timestamp: string, utcValue = '') => {
    const {relative, date, time } = getDateAndTimeFromISO(
      timestamp,
      utcValue
    );
    return (
      <Grid container direction="column">
        <Typography variant="h6">{relative}</Typography>
        <Typography variant="body2">{date}</Typography>
        <Typography variant="body2">{time}</Typography>
      </Grid>
    );
  };
  
  const renderUsername = (username: string, fullName: string) => {
    return (
      <Grid container direction="column">
        <Typography variant="h6">{username}</Typography>
        <Typography variant="body2">{fullName}</Typography>
      </Grid>
    );
  }

  return (
    <React.Fragment>
      <TableRow
        sx={{
          '.MuiTableCell-root': {
            borderBottom: '1px solid ' + EXTRA_COLORS.lightGrayishBlue,
            color: theme.palette.text.disabled,
          },
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.disabled,
          boxShadow: `0 0 0 1px ${EXTRA_COLORS.lightGrayishBlue} inset`,
        }}
      >
        <TableCell width={'4%'} sx={{ padding: 0 }}></TableCell>
        
        {props[COLUMN_NAME.USERNAME].display && (
          <TableCell
            sx={{ paddingLeft: 0 }}
            width={LOGS_TABLE_ITEMS_WIDTH.FIRST}
          >
            {renderUsername(props[COLUMN_NAME.USERNAME].value, props[COLUMN_NAME.FULL_NAME].value)}
          </TableCell>
        )}
        {props[COLUMN_NAME.USER_GROUP].display && (
          <TableCell
            width={LOGS_TABLE_ITEMS_WIDTH.REST_FIELDS}
            sx={{ paddingLeft: 0 }}
          >
            <Typography variant="body2">{props[COLUMN_NAME.USER_GROUP].value}</Typography>
          </TableCell>
        )}
        {props[COLUMN_NAME.APPS].display && (
          <TableCell width={LOGS_TABLE_ITEMS_WIDTH.REST_FIELDS}>
            <Typography variant="body2">{props[COLUMN_NAME.APPS].value}</Typography>
          </TableCell>
        )}
        {props[COLUMN_NAME.SESSION_START_TIME].display && 
          props[COLUMN_NAME.SESSION_START_TIME]?.value && (
          <TableCell width={LOGS_TABLE_ITEMS_WIDTH.REST_FIELDS}>
            {renderTimeStamp(props[COLUMN_NAME.SESSION_START_TIME]?.value, props[COLUMN_NAME.UTC]?.value)}
          </TableCell>
        )}
        {props[COLUMN_NAME.SESSION_END_TIME].display 
          && props[COLUMN_NAME.SESSION_END_TIME]?.value && (
            <TableCell width={LOGS_TABLE_ITEMS_WIDTH.REST_FIELDS}>
            {renderTimeStamp(props[COLUMN_NAME.SESSION_END_TIME]?.value, props[COLUMN_NAME.UTC]?.value)}
          </TableCell>
        )}
        {props[COLUMN_NAME.SESSION_DURATION].display && 
          props[COLUMN_NAME.SESSION_START_TIME]?.value && 
          props[COLUMN_NAME.SESSION_END_TIME]?.value && (
          <TableCell width={LOGS_TABLE_ITEMS_WIDTH.REST_FIELDS}>
            <Typography variant="body2">{
              formatTimeHoursMinuteSecond(
                Math.abs(new Date(props[COLUMN_NAME.SESSION_START_TIME]?.value).getTime() - new Date(props[COLUMN_NAME.SESSION_END_TIME]?.value).getTime()),
              )}
            </Typography>
          </TableCell>
        )}
      </TableRow>
    </React.Fragment>
  );
};

export default ReportTableRow;
